function Error(props: { error: string | undefined }) {
  if (props.error === undefined) {
    return null;
  } else {
    return (
      <p
        id="errorText"
        className="text-red-700 bg-red-100 rounded-lg px-2.5 py-0.5 text-sm font-medium mt-1"
      >
        {props.error}
      </p>
    );
  }
}

export default Error;
