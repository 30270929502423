import useQuestionStore from "@/stores/questionStore";
import { getRandomQuestions } from "@/utils/data/dataFetcher";
import { useState } from "react";
import { FiCheck, FiX } from "react-icons/fi";
import { Puff } from "react-loader-spinner";

interface QuestionProps {
  active?: boolean;
  handleAnswer: (correct: boolean) => void;
}

var pulled = false;

function Question(props: QuestionProps) {
  const [showAnswer, setShowAnswer] = useState(false);
  const questions = useQuestionStore((state) => state.questions);
  const currentQuestion = useQuestionStore((state) => state.currentIndex);
  const removeQuestion = useQuestionStore((state) => state.removeQuestion);
  const setQuestions = useQuestionStore((state) => state.setQuestions);

  const difficultyColor = [
    "black",
    "green-600",
    "yellow-600",
    "orange-600",
    "red-600",
    "red-900",
  ];

  const handleClicked = () => {
    setShowAnswer(false);
    removeQuestion(currentQuestion);
    if (currentQuestion - 1 < 0 && currentQuestion < questions.length - 1) {
      useQuestionStore.setState({
        currentIndex:
          currentQuestion + 1 === questions.length ? 0 : currentQuestion + 1,
      });
    }
  };

  if (questions.length <= 0 || currentQuestion >= questions.length) {
    if (currentQuestion >= questions.length && !pulled) {
      pulled = true;
      const fetchData = async () => {
        let newquestions: any[] = [];
        useQuestionStore.setState((state) => ({ page: state.page + 1 }));
        const q = await getRandomQuestions(useQuestionStore.getState().page);
        q.data.forEach((obj: any) => {
          newquestions.push(obj.attributes);
        });
        setQuestions(newquestions);
        console.debug("pulled");
      };
      fetchData()
        .catch(console.error)
        .then(() => (pulled = false));
    }

    return (
      <div>
        <Puff
          visible={true}
          height="50"
          width="50"
          color="orange"
          ariaLabel="puff-loading"
          wrapperStyle={{}}
          wrapperClass=""
        />
      </div>
    );
  }

  if (props.active === undefined) {
    props.active = true;
  }

  return (
    <div className="w-100 h-100 flex items-center flex-col">
      <h1
        className={
          "text-3xl font-bold text-center text-" +
          (props.active ? "black" : "gray-400")
        }
      >
        {questions[currentQuestion].text}
      </h1>
      {questions[currentQuestion].difficulty !== undefined ? (
        <p className={"text-" + +(props.active ? "black" : "gray-400")}>
          Schwierigkeit:{" "}
          <span
            className={
              "text-" +
              +(props.active
                ? difficultyColor[questions[currentQuestion].difficulty! - 1]
                : "gray-400")
            }
          >
            {questions[currentQuestion].difficulty}
          </span>{" "}
        </p>
      ) : null}
      <p className="mt-3 text-xl font-semibold text-center">
        {showAnswer ? "Antwort: " + questions[currentQuestion].answer : ""}
      </p>
      <button
        disabled={!props.active}
        className="bg-white mt-2 hover:bg-gray-100 text-gray-800 font-medium px-2 py-2 border border-gray-400 focus:ring-0 rounded-lg text-sm disabled:bg-gray-200 disabled:text-gray-400"
        onClick={() => {
          setShowAnswer(!showAnswer);
        }}
      >
        {showAnswer ? "Verstecke Antwort" : "Zeige Antwort"}
      </button>
      {showAnswer ? (
        <div className="flex flex-row items-center justify-center">
          <button
            onClick={() => {
              handleClicked();
              props.handleAnswer(true);
            }}
            title="Correct Answer"
            className="bg-green-500 mt-2 hover:bg-green-600 text-white font-medium px-3 py-2 focus:ring-0 rounded-lg text-sm"
          >
            <FiCheck size={20} />
          </button>
          <button
            title="Wrong Answer"
            onClick={() => {
              handleClicked();
              props.handleAnswer(false);
            }}
            className="bg-red-500 mt-2 ml-3 hover:bg-red-600 text-white font-medium px-3 py-2 focus:ring-0 rounded-lg text-sm"
          >
            <FiX size={20} />
          </button>
        </div>
      ) : null}
    </div>
  );
}

export default Question;
