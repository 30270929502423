import CreateGame from "@/components/creategame/CreateGame";
import useConfigStore from "@/stores/configStore";
import { gameModes } from "@/utils/globals";
import { useState } from "react";
import { Navigate } from "react-router-dom";

function GameMode() {
  const gameModeId = useConfigStore((s) => s.gameModeId);

  let comp = null;
  gameModes.forEach((m) => {
    if (m.id === gameModeId) {
      comp = <m.component />;
    }
  });
  if (comp === null) {
    comp = <Navigate to={"/notfound"} />;
  }
  return comp;
}

function Game() {
  const [ingame, setIngame] = useState(false);

  return (
    <div>
      {ingame ? <GameMode /> : <CreateGame startGame={() => setIngame(true)} />}
    </div>
  );
}

export default Game;
