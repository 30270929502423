import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface PlayerStore {
  players: {
    name: string;
    points: number;
    currentDifficulty: number;
    lifes: number;
  }[];
  playersOut: {
    name: string;
  }[];
  currentTurn: number;
  addPlayer: (item: {
    name: string;
    points: number;
    currentDifficulty: number;
    lifes: number;
  }) => void;
  removePlayer: (index: number) => void;
  nextTurn: () => void;
  addPointsToCurrent: (points: number) => void;
  removeLifesFromCurrent: (lifes: number) => void;
  removeLife: (index: number) => void;
  setLifesForPlayers: (lifes: number) => void;
}

const usePlayerStore = create<PlayerStore>()(
  immer((set) => ({
    players: [],
    playersOut: [],
    addPlayer: (item) => {
      set((state) => {
        void state.players.push(item);
      });
    },
    removePlayer: (index) => {
      set((state) => {
        state.players.splice(index, 1);
      });
    },
    currentTurn: 0,
    nextTurn: () => {
      set((state) => ({
        currentTurn:
          state.currentTurn + 1 >= state.players.length
            ? 0
            : state.currentTurn + 1,
      }));
    },
    addPointsToCurrent: (points) => {
      set((state) => {
        void state.players[state.currentTurn].points++;
      });
    },
    removeLifesFromCurrent: (lifes) => {
      set((state) => {
        void state.players[state.currentTurn].lifes--;
      });
    },
    removeLife: (index) => {
      set((state) => {
        if (state.players[index].lifes - 1 <= 0) {
          void state.players.splice(index, 1);
          void state.playersOut.push({ name: state.players[index].name });
        } else {
          void state.players[index].lifes--;
        }
      });
    },
    setLifesForPlayers: (lifes) => {
      set((state) => {
        state.players.forEach((item) => {
          item.lifes = lifes;
        });
      });
    },
  }))
);

export default usePlayerStore;
