import {
  AiFillCheckCircle,
  AiFillHeart,
  AiOutlineCloseCircle,
  AiOutlineHeart,
} from "react-icons/ai";

interface CPlayerProps {
  player: {
    name: string;
    points: number;
    currentDifficulty: number;
    lifes: number;
  };
  maxHealth: number;
  semiRound: number;
  currentTurn: number;
  ownIndex: number;
}

function CPlayer(props: CPlayerProps) {
  return (
    <div className="flex flex-col">
      <div className="flex flex-row">
        <h1
          className={
            "mr-2 text-" +
            (props.currentTurn === props.ownIndex ? "orange-500" : "black")
          }
        >
          {props.player.name}
        </h1>
        <div className="lifes flex flex-row items-center">
          {Array(props.player.points).fill(<AiFillCheckCircle color="green" />)}
          {Array(props.semiRound - props.player.points).fill(
            <AiOutlineCloseCircle color="red" />
          )}
        </div>
      </div>
      <div className="lifes flex flex-row items-center">
        {[...Array(props.maxHealth)].map((_, index) =>
          index < props.player.lifes ? (
            <AiFillHeart color="red" />
          ) : (
            <AiOutlineHeart color="red" />
          )
        )}
      </div>
    </div>
  );
}

export default CPlayer;
