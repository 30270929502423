import usePlayerStore from "@/stores/playerStore";
import { useState } from "react";
import { FiPlusCircle, FiXCircle } from "react-icons/fi";
import { StageProps } from "./interface";

function AddPlayer(props: StageProps) {
  const players = usePlayerStore((state) => state.players);
  const [addPlayer, setAddPlayer] = useState("");
  const addPlayerToList = usePlayerStore((state) => state.addPlayer);
  const removePlayerFromList = usePlayerStore((state) => state.removePlayer);

  const addPlayerClick = () => {
    let valid: boolean = true;
    if (
      addPlayer === undefined ||
      addPlayer === "" ||
      addPlayer.includes(" ")
    ) {
      props.setError("Dieser Name ist nicht zulässig.");
      return;
    } else {
      players.forEach((p) => {
        if (p.name === addPlayer) {
          valid = false;
        }
      });
    }

    if (valid) {
      addPlayerToList({
        name: addPlayer,
        points: 0,
        currentDifficulty: 0,
        lifes: -1,
      });
      setAddPlayer("");
    } else {
      props.setError("Dieser Name existiert bereits.");
    }
  };

  return (
    <div>
      <div className="flex flex-row items-center justify-center">
        <input
          className="border rounded-lg px-3 py-1.5 mr-3"
          placeholder="Spieler hinzufügen"
          type="text"
          onKeyDown={(e) => {
            if (e.key.toUpperCase() === "ENTER") {
              addPlayerClick();
            }
          }}
          value={addPlayer}
          onChange={(e) => {
            setAddPlayer(e.target.value);
            if (props.error !== undefined) {
              props.setError(undefined);
            }
          }}
        />
        <button
          className="bg-orange-400 rounded-xl p-2.5"
          onClick={addPlayerClick}
        >
          <FiPlusCircle color="white" size={20} />
        </button>
      </div>
      <ul className={"my-5 border rounded-lg px-5 py-5"}>
        {players.length <= 0 ? (
          <p className="text-center font-medium text-md">Keine Spieler</p>
        ) : (
          players.map((player, index) => (
            <li
              className="flex flex-row items-center text-lg font-medium"
              key={player.name + "_" + index}
            >
              {index + 1}. {player.name}
              <button
                className="ml-1"
                onClick={() => {
                  players.forEach((p, i) => {
                    if (p.name === player.name) {
                      removePlayerFromList(i);
                    }
                  });
                }}
              >
                <FiXCircle color="red" />
              </button>
            </li>
          ))
        )}
      </ul>
    </div>
  );
}

export default AddPlayer;
