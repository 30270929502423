interface EPlayerProps {
  player: {
    name: string;
    points: number;
    currentDifficulty: number;
    lifes: number;
  };
  currentTurn: number;
  displayIndex: number;
  ownIndex: number;
  top3: boolean;
}

function EPlayer(props: EPlayerProps) {
  const topThreeColors = ["yellow-700", "gray-400", "orange-600"];

  return (
    <div className="flex flex-col justify-start">
      <div
        className={
          "flex flex-row sm:mr-10 py-1 px-2 " +
          (props.currentTurn === props.ownIndex
            ? "bg-orange-200 rounded-lg"
            : "")
        }
      >
        <p
          className={"mr-2 text-" + (props.top3 ? "xl" : "md") + " font-medium"}
        >
          {props.displayIndex + (props.top3 ? 1 : 4)}.
        </p>
        <p
          className={
            "text-" +
            (props.top3 ? "xl" : "md") +
            " font-medium pr-1 text-" +
            (props.top3 ? topThreeColors[props.displayIndex] : "black")
          }
        >
          {props.player.name}
        </p>
        <p
          className={
            "text-" + (props.top3 ? "xl" : "md") + " font-medium text-black"
          }
        >
          [{props.player.points}]
        </p>
      </div>
    </div>
  );
}

export default EPlayer;
