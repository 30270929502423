import Notfound from "@/assets/img/icon_notfound.png";
import { Link } from "react-router-dom";

function NotFound() {
  return (
    <div>
      <div
        className="flex flex-col items-center justify-center"
        style={{ height: "96vh" }}
      >
        <img src={Notfound} alt="" height={100} width={100} />
        <h2 className="mt-10 text-8xl font-bold text-black">404</h2>
        <h1 className="text-lg font-semibold text-black">Nicht gefunden</h1>
        <p className="mb-10">Diese Unterseite gibt es nicht.</p>
        <Link to={"/"} className="text-md font-semibold text-black">
          Zurück zur Startseite
        </Link>
      </div>
      <footer className="flex items-center justify-center">
        <a
          href="https://www.flaticon.com/free-icons/page-not-found"
          title="page not found icons"
          className="text-sm text-gray-400"
        >
          Page not found icons created by Pixel perfect - Flaticon
        </a>
      </footer>
    </div>
  );
}

export default NotFound;
