import Leaderboard from "@/components/endless/leaderboard/Leaderboard";
import Question from "@/components/question/Question";
import { default as userPlayerStore } from "@/stores/playerStore";
import useQuestionStore from "@/stores/questionStore";
import { getRandomQuestions } from "@/utils/data/dataFetcher";
import { useState } from "react";
import { FiXCircle } from "react-icons/fi";

var pulled = false;

function Endless() {
  const [loading, setLoading] = useState(true);
  const [round, setRound] = useState(1);
  const players = userPlayerStore((state) => state.players);
  const currentPlayer = userPlayerStore((state) => state.currentTurn);
  const setQuestions = useQuestionStore((state) => state.setQuestions);

  const fetchData = async () => {
    let newquestions: any[] = [];
    const q = await getRandomQuestions();
    q.data.forEach((obj: any) => {
      newquestions.push(obj.attributes);
    });
    setQuestions(newquestions);
    pulled = true;
    console.debug("pulled");
  };
  if (!pulled) {
    fetchData()
      .catch(console.error)
      .then(() => setLoading(false));
  }

  return (
    <div className="h-lvh lg:w-auto flex flex-col justify-center items-center pt-20 sm:pt-40">
      <a
        href={"/"}
        onClick={() => {
          window.location.reload();
        }}
        className="flex sm:hidden bg-red-400 px-3 py-2 mb-3 text-sm rounded-xl text-white font-medium shadow border-2 border-red-200"
      >
        <div className="flex flex-row items-center">
          <FiXCircle size={20} />
        </div>
      </a>
      <div className="h-1/5 info mb-10 flex flex-col items-center">
        <p>
          Runde
          <span className="font-semibold">#{round}</span>
        </p>
        <p>
          <span className="font-semibold">
            {" "}
            {players[currentPlayer] === undefined
              ? "Unbekannt"
              : players[currentPlayer].name}
          </span>{" "}
          ist an der Reihe.
        </p>
      </div>
      <div className="h-4/5 flex flex-col justify-center items-center mb-20 mt-14 ">
        <div className="flex flex-col items-center justify-center">
          <div className="lg:px-40 py-14">
            {loading ? null : (
              <Question
                active={true}
                handleAnswer={(right) => {
                  if (right) {
                    userPlayerStore.getState().addPointsToCurrent(1);
                  }
                  userPlayerStore.getState().nextTurn();
                  setRound(round + 1);
                }}
              />
            )}
          </div>
        </div>
        <Leaderboard />
      </div>
      <a
        href={"/"}
        onClick={() => {
          window.location.reload();
        }}
        className="sm:flex hidden bg-red-500 px-3 py-3 text-sm rounded-xl text-white font-medium shadow border-2 border-red-200 self-end sticky top-[100vh] sm:self-auto sm:relative sm:top-0"
      >
        <div className="flex flex-row items-center">
          <FiXCircle className="mr-2" size={20} />
          Spiel beenden
        </div>
      </a>
    </div>
  );
}

export default Endless;
