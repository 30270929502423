import Classic from "@/routes/game/ingame/classic/Classic";
import Endless from "@/routes/game/ingame/endless/Endless";

const gameModes: { id: number; title: string; component: () => JSX.Element }[] =
  [
    {
      id: 0,
      title: "Klassisch",
      component: Classic,
    },
    {
      id: 1,
      title: "Endless Ranking",
      component: Endless,
    },
  ];

const maxLifes: number = 10;
const minLifes: number = 1;

export { gameModes, maxLifes, minLifes };
