import useConfigStore from "@/stores/configStore";
import { gameModes } from "@/utils/globals";
import { StageProps } from "./interface";

function GameMode(props: StageProps) {
  const selected = useConfigStore((state) => state.gameModeId);
  const changeGameMode = (id: number) => {
    useConfigStore.setState({ gameModeId: id });
  };

  return (
    <div className="flex flex-row justify-center">
      {gameModes.map((gameMode) => {
        return (
          <div
            className={
              "m-2 px-4 py-4 text-center " +
              (selected === gameMode.id
                ? "bg-orange-300 font-semibold rounded-xl"
                : "font-medium")
            }
            onClick={() => changeGameMode(gameMode.id)}
            style={{ cursor: "pointer" }}
          >
            <p className="text-md">{gameMode.title}</p>
          </div>
        );
      })}
    </div>
  );
}

export default GameMode;
