import useConfigStore from "@/stores/configStore";
import usePlayerStore from "@/stores/playerStore";
import { useState } from "react";
import Error from "../error/Error";
import "./creategame.css";
import AddPlayer from "./stages/AddPlayer";
import AiPowered from "./stages/AiPowered";
import GameMode from "./stages/GameMode";
import Life from "./stages/Life";

function CreateGame({ startGame }: { startGame: () => void }) {
  const players = usePlayerStore((state) => state.players);
  const [error, setError] = useState<string | undefined>(undefined);
  const setLifesForPlayers = usePlayerStore(
    (state) => state.setLifesForPlayers
  );

  const checkValidGame = () => {
    if (players.length <= 1) {
      setError("Du musst mindestens 2 Spieler erstellen.");
    } else {
      setLifesForPlayers(useConfigStore.getState().lifes);
      startGame();
    }
  };

  return (
    <div className="mt-10 lg:mt-0 lg:h-screen  flex flex-col items-center justify-evenly lg:justify-center">
      <h2 className="text-black font-medium text-xl text-center">
        Der dümmste fliegt!
      </h2>
      <h1 className="text-black font-bold text-4xl text-center my-5 sm:text-5xl">
        Erstelle eine Runde
      </h1>
      <div className="lg:w-screen flex flex-col lg:flex-row justify-center items-center mb-10 lg:mb-0">
        <div className="configcontainer border-2 rounded-lg px-10 mx-8 mt-5 lg:mt-0 p-5">
          <h1 className="text-2xl text-center mb-4 font-semibold">Spieler</h1>
          <AddPlayer setError={setError} error={error} />
        </div>
        <div className="configcontainer border-2 rounded-lg px-10 mx-8 mt-5 lg:mt-0 p-5">
          <h1 className="text-2xl text-center mb-4 font-semibold">
            Spielmodus
          </h1>
          <GameMode setError={setError} error={error} />
        </div>
        <div className="configcontainer border-2 rounded-lg px-10 mx-8 mt-5 lg:mt-0 p-5">
          <h1 className="text-2xl text-center mb-4 font-semibold">
            Spielerleben
          </h1>
          <Life setError={setError} error={error} />
        </div>
        <div className="configcontainer border-2 rounded-lg px-10 mx-8 mt-5 lg:mt-0 p-5">
          <h1 className="text-2xl text-center mb-4 font-semibold">
            Ki-Erstellte Fragen
            <span className="ml-2 bg-yellow-200 text-yellow-800 text-xs font-medium px-2.5 py-0.5 rounded">
              ALPHA
            </span>
          </h1>
          <AiPowered setError={setError} error={error} />
        </div>
      </div>

      <div className="buttons flex flex-row items-center justify-center mb-4 mt-5">
        <button
          onClick={checkValidGame}
          className="bg-orange-400 hover:bg-orange-500 text-white font-semibold py-2 px-6 border border-orange-100  rounded-lg shadow mr-3"
        >
          Spiel starten
        </button>
        <a
          onClick={() => {
            window.location.reload();
          }}
          href={"/"}
          className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-6 border border-gray-400 rounded-lg shadow"
        >
          Zurück
        </a>
      </div>
      <Error error={error} />
    </div>
  );
}

export default CreateGame;
