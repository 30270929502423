import useConfigStore from "@/stores/configStore";
import { maxLifes, minLifes } from "@/utils/globals";
import { StageProps } from "./interface";

function Life(props: StageProps) {
  const lifes = useConfigStore((state) => state.lifes);
  const gameModeID = useConfigStore((state) => state.gameModeId);

  return (
    <div className="flex flex-col items-center">
      {gameModeID === 1 ? (
        <input
          id="minmax-range"
          type="range"
          disabled={true}
          min={minLifes}
          max={maxLifes}
          value={lifes}
          onChange={(e) =>
            useConfigStore.setState({ lifes: parseInt(e.target.value) })
          }
          className="w-full h-2 bg-gray-100 rounded appearance-none cursor-pointer mb-4 accent-gray-400"
        />
      ) : (
        <input
          id="minmax-range"
          type="range"
          min={minLifes}
          max={maxLifes}
          value={lifes}
          onChange={(e) =>
            useConfigStore.setState({ lifes: parseInt(e.target.value) })
          }
          className="w-full h-2 bg-gray-100 rounded appearance-none cursor-pointer mb-4 accent-orange-400"
        />
      )}
      <p
        className={
          "text-lg font-medium text-" +
          (gameModeID === 1 ? "gray-400" : "black")
        }
      >
        Leben: {lifes}
      </p>
    </div>
  );
}

export default Life;
