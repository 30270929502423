import { Dispatch, SetStateAction, useEffect } from "react";

function Countdown(props: {
  timeInSec: number;
  setTimeInSec: Dispatch<SetStateAction<number>>;
  onEnd: () => void;
}) {
  const { timeInSec, onEnd, setTimeInSec } = props;
  useEffect(() => {
    if (timeInSec <= 0) {
      onEnd();
      return;
    }

    const timer = setInterval(() => {
      setTimeInSec((seconds: number) => {
        return seconds - 1;
      });
    }, 1000);

    return () => {
      clearInterval(timer);
    };
  }, [onEnd, setTimeInSec, timeInSec]);

  return (
    <div className="flex flex-row text-lg font-semibold">
      <p className="font-medium mr-1">Restliche Zeit:</p>
      <p
        className={
          timeInSec === 60 ||
          timeInSec === 30 ||
          timeInSec === 10 ||
          timeInSec <= 5
            ? "text-red-500"
            : "text-black"
        }
      >
        {Math.floor(timeInSec / 60)
          .toString()
          .padStart(2, "0")}
      </p>
      <p
        className={
          timeInSec === 60 ||
          timeInSec === 30 ||
          timeInSec === 10 ||
          timeInSec <= 5
            ? "text-red-500"
            : "text-black"
        }
      >
        :
      </p>
      <p
        className={
          timeInSec === 60 ||
          timeInSec === 30 ||
          timeInSec === 10 ||
          timeInSec <= 5
            ? "text-red-500"
            : "text-black"
        }
      >
        {(timeInSec % 60).toString().padStart(2, "0")}
      </p>
    </div>
  );
}

export default Countdown;
