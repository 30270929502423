import axios from "axios";

//var randomId = ids.data[Math.floor(Math.random() * ids.data.length)].id;

export async function getRandomQuestions(page?: number) {
  const res = await axios.get(
    `${
      process.env.REACT_APP_API_URL
    }/api/questions?randomSort=true&pagination[page]=${
      page !== undefined ? page : 0
    }&pagination[pageSize]=25&fields[0]=text&fields[1]=answer&fields[2]=difficulty`
  );
  return res.data;
}
