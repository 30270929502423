import Countdown from "@/components/classic/Countdown";
import PlayerList from "@/components/classic/PlayerList";
import Voting from "@/components/classic/Voting";
import Question from "@/components/question/Question";
import usePlayerStore from "@/stores/playerStore";
import { useState } from "react";
import { FiXCircle } from "react-icons/fi";

function Classic() {
  const [semiRound, setSemiRound] = useState(1);
  const [round, setRound] = useState(0);
  const [timeRemaining, setTimeRemaining] = useState(60);
  const [voting, setVoting] = useState(false);
  const [disabled, setDisabled] = useState(false);
  const currentTurn = usePlayerStore((state) => state.currentTurn);
  const players = usePlayerStore((state) => state.players);
  const onVotingEnd = () => {
    setDisabled(false);
    setVoting(false);
    setRound((round) => round + 1);
    setSemiRound(1);
    setTimeRemaining(60);
  };

  return (
    <div className="h-screen flex flex-col items-center">
      <div style={{ height: "94%" }} className="w-screen">
        <div
          style={{ height: "94%" }}
          className="flex xl:flex-row flex-col-reverse xl:mx-14 py-3 justify-center items-center"
        >
          <div className="xl:basis-1/4 w-96 xl:w-auto px-10 xl:px-3 border-2 border-gray-200 mr-4 rounded-lg p-3 divide-y-2">
            <p>
              Runde #{round + 1} (Semi: #{semiRound})
            </p>
            <Countdown
              onEnd={() => {
                setDisabled(true);
                setVoting(true);
              }}
              setTimeInSec={setTimeRemaining}
              timeInSec={timeRemaining}
            />
            <PlayerList semiRound={semiRound} />
          </div>
          <div className="xl:basis-3/4 w-screen xl:w-auto xl:px-3 px-4 p-3">
            {!voting ? (
              <Question
                active={!disabled}
                handleAnswer={(right) => {
                  if (right) {
                    usePlayerStore.getState().addPointsToCurrent(1);
                  }
                  usePlayerStore.getState().nextTurn();
                  if (currentTurn >= players.length - 1) {
                    setSemiRound((round) => round + 1);
                  }
                }}
              />
            ) : (
              <Voting onFinish={onVotingEnd} />
            )}
          </div>
        </div>
      </div>

      <a
        href={"/"}
        onClick={() => {
          window.location.reload();
        }}
        className="flex bg-red-500 px-3 py-3 text-sm rounded-xl text-white font-medium shadow border-2 border-red-200 w-40"
      >
        <div className="flex flex-row items-center">
          <FiXCircle className="mr-2" size={20} />
          Spiel beenden
        </div>
      </a>
    </div>
  );
}

export default Classic;
