import usePlayerStore from "@/stores/playerStore";
import { useState } from "react";
import { AiFillMinusCircle, AiFillPlusCircle } from "react-icons/ai";
import Error from "../error/Error";

type VoteType = {
  name: string;
  votes: number;
}[];

function Voting(props: { onFinish: () => void }) {
  const players = usePlayerStore((state) => state.players);
  const removePlayerLife = usePlayerStore((state) => state.removeLife);
  const [votes, setVotes] = useState<VoteType>([]);
  const [error, setError] = useState<string | undefined>(undefined);

  const finish = () => {
    if (votes.length > 0) {
      removePlayerLife(
        players.findIndex((player) => player.name === votes[0].name)
      );
    }
    props.onFinish();
  };

  return (
    <div className="flex flex-col items-center justify-center">
      <h1 className="text-center text-2xl font-semibold">Voting</h1>
      <h2 className="text-center text-xl font-medium">Wer ist der dümmste?</h2>
      <div className="flex flex-col justify-center items-center">
        {[...players]
          .sort((a, b) => {
            return (
              (votes.find((vote) => vote.name === b.name)?.votes ?? 0) -
              (votes.find((vote) => vote.name === a.name)?.votes ?? 0)
            );
          })
          .map((player, index) => {
            return (
              <div
                className="my-2 flex flex-row justify-center items-center"
                key={player.name}
              >
                <div
                  className={
                    "rounded-lg py-2 px-5 bg-" +
                    (index === 0 ? "red-400" : "gray-100")
                  }
                >
                  <p>{player.name}</p>
                </div>
                <p className="ml-3" style={{ width: "75px" }}>
                  Votes:{" "}
                  {votes.find((vote) => vote.name === player.name)?.votes ?? 0}
                </p>
                <button
                  className="ml-4"
                  onClick={() => {
                    const newVotes = [...votes];
                    const index = newVotes.findIndex(
                      (vote) => vote.name === player.name
                    );
                    if (index === -1) {
                      newVotes.push({ name: player.name, votes: 1 });
                    } else {
                      if (
                        votes.reduce((total, vote) => total + vote.votes, 0) <
                        players.length
                      ) {
                        newVotes[index].votes++;
                      } else {
                        setError("Jeder Spieler hat genau einen Vote.");
                      }
                    }
                    setVotes(newVotes);
                  }}
                >
                  <AiFillPlusCircle />
                </button>
                <button
                  className="ml-1"
                  onClick={() => {
                    const newVotes = [...votes];
                    const index = newVotes.findIndex(
                      (vote) => vote.name === player.name
                    );
                    if (index === -1) {
                      newVotes.push({ name: player.name, votes: 0 });
                    } else {
                      if (error !== undefined) {
                        setError(undefined);
                      }
                      if (newVotes[index].votes > 0) {
                        newVotes[index].votes--;
                      } else {
                        newVotes.splice(index, 1);
                      }
                    }
                    setVotes(newVotes);
                  }}
                >
                  <AiFillMinusCircle />
                </button>
              </div>
            );
          })}
      </div>
      <button
        onClick={finish}
        className="bg-white my-2 hover:bg-gray-100 text-gray-800 font-medium px-2 py-2 border border-gray-400 focus:ring-0 rounded-lg text-sm"
      >
        Voting beenden
      </button>
      <Error error={error} />
    </div>
  );
}

export default Voting;
