import useConfigStore from "@/stores/configStore";
import { StageProps } from "./interface";

function AiPowered(props: StageProps) {
  const aiPowered = useConfigStore((state) => state.aiPowered);
  const changeAiPowered = (val: boolean) => {
    useConfigStore.setState({ aiPowered: val });
  };

  return (
    <div className="flex flex-col justify-center">
      <p className="mb-4 text-center w-72 lg:w-auto">
        Sollen von einer künstlichen Intelligenz erstelle Fragen verwendet
        werden?
      </p>
      <input
        checked={aiPowered}
        onChange={(e) => changeAiPowered(e.target.checked)}
        className="rounded-lg accent-orange-400 h-5"
        type="checkbox"
      />
      <p className="mt-2 text-center w-70">{aiPowered ? "Ja" : "Nein"}</p>
    </div>
  );
}

export default AiPowered;
