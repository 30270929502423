import { create } from "zustand";

interface ConfigStore {
  lifes: number;
  gameModeId: number;
  aiPowered: boolean;
}

const useConfigStore = create<ConfigStore>((set) => ({
  lifes: 3,
  gameModeId: 0,
  aiPowered: false,
}));

export default useConfigStore;
