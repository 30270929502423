import usePlayerStore from "@/stores/playerStore";
import EPlayer from "./player/EPlayer";

function Leaderboard() {
  const players = usePlayerStore((store) => store.players);
  const currentTurn = usePlayerStore((store) => store.currentTurn);
  return (
    <div className="h-2/5 flex flex-col items-center justify-center">
      <h2 className="font-semibold text-xl text-center mb-2">Top 3</h2>
      <div className="flex flex-col sm:flex-row items-center">
        {[...players]
          .slice()
          .sort((a, b) => {
            return b.points - a.points;
          })
          .slice(0, 3)
          .map((player, index) => {
            return (
              <EPlayer
                currentTurn={currentTurn}
                displayIndex={index}
                ownIndex={players.indexOf(player)}
                player={player}
                top3={true}
                key={player.name + players.indexOf(player)}
              />
            );
          })}
      </div>
      <h2 className="font-semibold text-lg text-center mt-2">Others</h2>
      <div className="flex flex-col flex-wrap justify-center w-auto sm:w-2/3 sm:flex-row items-center">
        {[...players]
          .slice()
          .sort((a, b) => {
            return b.points - a.points;
          })
          .slice(3)
          .map((player, index) => {
            return (
              <EPlayer
                currentTurn={currentTurn}
                displayIndex={index}
                ownIndex={players.indexOf(player)}
                player={player}
                top3={false}
                key={player.name + players.indexOf(player)}
              />
            );
          })}
      </div>
    </div>
  );
}

export default Leaderboard;
