import useConfigStore from "@/stores/configStore";
import usePlayerStore from "@/stores/playerStore";
import { AiOutlineHeart } from "react-icons/ai";
import CPlayer from "./player/CPlayer";

function PlayerList(props: { semiRound: number }) {
  const players = usePlayerStore((store) => store.players);
  const playersOut = usePlayerStore((store) => store.playersOut);
  const currentTurn = usePlayerStore((store) => store.currentTurn);
  const maxHealth = useConfigStore((store) => store.lifes);
  return (
    <div>
      {[...players]
        .slice()
        .sort((a, b) => {
          return b.points - a.points;
        })
        .map((item, index) => {
          return (
            <CPlayer
              semiRound={props.semiRound}
              player={item}
              maxHealth={maxHealth}
              ownIndex={players.indexOf(item)}
              currentTurn={currentTurn}
            />
          );
        })}
      {[...playersOut].slice().map((item, index) => {
        return (
          <div className="flex flex-col">
            <p className="text-md text-gray-400">{item.name}</p>
            <div className="flex flex-row items-center">
              {[...Array(maxHealth)].map((_, index) => (
                <AiOutlineHeart color="gray" />
              ))}
            </div>
          </div>
        );
      })}
    </div>
  );
}

export default PlayerList;
