function Info() {
  return (
    <div>
      <p className="text-center text-md font-semibold">
        Willkommen bei "Der dümmste fliegt!"
      </p>
      <div className="divide-y">
        <p className="text-center text-sm font-medium my-2">
          Das Prinzip hinter "Der dümmste fliegt!" stammt von PietSmiet bzw.
          wurde populär durch RevedTV.
        </p>
        <p className="text-center text-sm font-medium my-2">
          Derzeit gibt es folgende Spielmodi:
          <ul>
            <li className="font-semibold">Klassisch</li>
            <li className="font-semibold">Endlos</li>
          </ul>
        </p>
        <p className="text-center text-sm font-medium my-2">
          Klassisch:
          <br />
          In diesem Modi spielt man nach dem klassischen Spielprinzip. In einer
          gewissen Zeit werden nacheinander Fragen beantwortet. Im Leaderboard
          wird angezeigt, wer eine Frage richtig hat und wer entsprechend
          daneben lag. Am Ende der "Semi-Runde" wird abgestimmt, wer die
          dümmsten Antworten oder auch meisten Fehler gemacht hat. Der gewählte
          Spieler verliert ein Leben. Hat ein Spieler 0 Leben, scheidet er aus.
          Der Gewinner ist der, der am Enden noch am meisten Leben hat oder als
          einziger "überlebt".
        </p>
        <p className="text-center text-sm font-medium my-2">
          Endlos:
          <br />
          Bei diesem Modi werden endlos Fragen beantwortet. Bei einer richtigen
          Frage, erhält der Spieler einen Punkt. Ist die Antwort falsch, erhält
          er entsprechend keinen Punkt.
        </p>
        <p className="text-center text-sm font-medium my-2">
          Um das Spiel zu beenden, klickt man auf den "Spiel beenden" Button
          bzw. auf den Button mit dem roten X.
        </p>
      </div>
    </div>
  );
}

export default Info;
