import { Link } from "react-router-dom";
import logoicon from "./assets/img/icon_home.png";
import TextModal from "./components/textmodal/TextModal";
import Info from "./components/textmodal/texts/Info";

function App() {
  return (
    <div className="App">
      <div className="h-screen homescreen">
        <div
          className="flex flex-col items-center justify-center"
          style={{ height: "85.5%" }}
        >
          <div className="flex flex-col items-center">
            <img
              className="mb-10"
              src={logoicon}
              alt=""
              height={125}
              width={125}
              draggable={false}
            />
            <div className="titles flex flex-col items-center mb-5">
              <h2 className="domain text-orange-500 text-center mb-3 font-medium text-xl">
                ddf.lol
              </h2>
              <h1 className="title text-black text-center font-bold text-5xl">
                Der dümmste fliegt!
              </h1>
            </div>
          </div>
          <Link
            className="bg-white hover:bg-gray-100 text-gray-800 font-semibold py-2 px-4 border border-gray-400 rounded shadow"
            to="/game"
          >
            Start
          </Link>
          <div className="flex flex-row items-center mt-8">
            <TextModal title="Info" text={<Info />} />
            <div className="divider mx-3">&bull;</div>
            <a
              href="https://github.com/42nao/"
              target="_blank"
              className="font-medium text-orange-600 dark:text-orange-500 hover:none"
              rel="noreferrer"
            >
              Github
            </a>
          </div>
        </div>
        <footer className="text-center">
          &copy; {new Date().getFullYear()}{" "}
          <span className="text-orange-600">ddf.lol</span>
          <div className="flex flex-row items-center justify-center">
            Es werden keine personbezogenen Daten erhoben.
          </div>
          <div className="text-sm">
            Hosting:{" "}
            <a
              className="text-orange-400"
              href="https://www.netlify.com"
              target="_blank"
              rel="noreferrer"
            >
              netlify.com (Frontend)
            </a>{" "}
            |{" "}
            <a
              className="text-orange-400"
              href="https://railway.app"
              target="_blank"
              rel="noreferrer"
            >
              railway.app (Backend)
            </a>
          </div>
          <a
            href="https://www.flaticon.com/free-icons/question-mark"
            title="question mark icons"
            style={{ fontSize: 12 }}
          >
            Question mark icons created by Freepik - Flaticon
          </a>
        </footer>
      </div>
    </div>
  );
}

export default App;
