import { create } from "zustand";
import { immer } from "zustand/middleware/immer";

interface QuestionStore {
  questions: { text: string; answer: string; difficulty?: number }[];
  currentIndex: number;
  page: number;
  setQuestions: (
    questions: { text: string; answer: string; difficulty?: number }[]
  ) => void;
  removeQuestion: (index: number) => void;
}

const useQuestionStore = create<QuestionStore>()(
  immer((set) => ({
    questions: [],
    currentIndex: 0,
    page: 0,
    setQuestions: (
      newquestions: { text: string; answer: string; difficulty?: number }[]
    ) => {
      set({ questions: newquestions });
    },
    removeQuestion: (index) => {
      set((state) => {
        state.questions.splice(index, 1);
      });
    },
  }))
);

export default useQuestionStore;
